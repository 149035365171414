/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */

import divisions from "@ui/helpers/_constants/urlDivisionsParams";
import { fetchPrescribers, searchUsers } from "@/modules/common/_api";
import * as api from "../_api";

const { ADMINISTRATEUR, COMMERCIAL, DIRECTEUR_COMMERCIAL } = require("@common/constants/roles");

const state = {
  prescribers: [],
  currentDivision: null,
  count: {
    r: 0,
    n: 0,
    p: 0,
    d: 0
  },
  currentCount: 0,
  formattedQuery: {},
  lastRequestId: null
};

const getters = {
  prescribers: state => state.prescribers,
  currentDivision: state => state.currentDivision,
  count: state => state.count,
  currentCount: state => state.currentCount,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_PRESCRIBERS: (state, prescribers) => {
    state.prescribers = prescribers;
  },
  SET_CURRENT_DIVISION: (state, division) => {
    const currentDivisionParams = divisions.find(item => item.code === division).params;
    state.currentDivision = {
      code: division,
      params: currentDivisionParams
    };
  },
  SET_COUNT: (state, { division, count }) => {
    state.count[division] = count;
  },
  SET_CURRENT_COUNT: (state, currentCount) => {
    state.currentCount = currentCount;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  }
};

const actions = {
  exportPrescribers(context, params) {
    const { formattedQuery, currentDivision } = context.state;
    const { _id, email, firstname, lastname } = context.rootState.login.user;

    return api.exportPrescribers({
      ...params,
      query: {
        ...formattedQuery,
        division: currentDivision.code,
        user: {
          email,
          firstname,
          lastname,
          _id
        }
      }
    });
  },
  getPrescribersCount(context, params) {
    return new Promise((resolve, reject) => {
      api
        .getPrescribersCount(params)
        .then(count => {
          context.commit("SET_COUNT", { division: params.query.division.toLowerCase(), count });
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribers(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId, currentDivision } = context.state;
      fetchPrescribers({ ...params, query: { ...formattedQuery, division: currentDivision.code } })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          const { results, count } = res.data.body;
          context.commit("SET_PRESCRIBERS", results);
          context.commit("SET_CURRENT_COUNT", count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { prescribers, delegates, ...formattedQuery } = query;
    if (prescribers && prescribers.length) {
      formattedQuery.prescriberIds = prescribers.map(item => item._id);
    }
    if (delegates && delegates.length) {
      formattedQuery.delegateIds = delegates.map(item => item._id);
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  /*
   * We use custom searchUSers instead of common searchDelegates here because
   * we want to search for delegates and delegates users which are adminstrators
   * Context from V2
   */
  searchUsers(context, input) {
    return searchUsers({
      query: {
        input,
        roleIds: [ADMINISTRATEUR.id, COMMERCIAL.id, DIRECTEUR_COMMERCIAL.id],
        active: true
      },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  setCurrentDivision(context, division) {
    context.commit("SET_CURRENT_DIVISION", division);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
