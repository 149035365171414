import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import defaultErrorMessages from "@/helpers/_constants/defaultErrorMessages";
import {
  fetchInterventions,
  fetchDeliveries,
  fetchUserEvents,
  searchPatients,
  fetchZones,
  fetchAllStocksContent,
  fetchCommunityStockContents
} from "@/modules/common/_api";
import { fetchEntityInterventions } from "@/modules/entity-interventions/common/_api";

import { generateOfflinePdf } from "../_api";
import _ical from "./ical";
import _signature from "./signature";

const removeOldDeliveredConsommables = require("@common/helpers/deliveries/removeOldDeliveredConsommables");

const interventionStatuses = require("@common/constants/interventionStatuses");
const entityInterventionStatuses = require("@common/constants/entityInterventionStatuses");

const deliveryStatuses = require("@common/constants/deliveryStatuses");

/* eslint-disable no-shadow */

const getInitialFeedbackMessages = () => {
  return {
    success: null,
    error: null,
    errorForm: null,
    successModal: null,
    errorModal: null,
    signatureSuccess: null,
    signatureError: null
  };
};

const state = {
  interventions: [],
  interventionsCount: 0,
  userEvents: [],
  entityInterventions: [],
  zones: [],
  forfaits: [],
  query: {},
  feedbackMessages: getInitialFeedbackMessages(),
  basicParams: {},
  communityStocks: {},
  ..._ical.state
};

const getters = {
  interventions: state => state.interventions,
  interventionsCount: state => state.interventionsCount,
  userEvents: state => state.userEvents,
  entityInterventions: state => state.entityInterventions,
  zones: state => state.zones,
  taxonomies: state => state.taxonomies,
  query: state => state.query,
  feedbackMessages: state => state.feedbackMessages,
  communityStocks: state => state.communityStocks,
  ..._ical.getters
};

const mutations = {
  SET_INTERVENTIONS: (state, interventions) => {
    state.interventions = interventions;
  },
  SET_INTERVENTIONS_COUNT: (state, interventionsCount) => {
    state.interventionsCount = interventionsCount;
  },
  SET_USER_EVENTS: (state, userEvents) => {
    state.userEvents = userEvents;
  },
  SET_ENTITY_INTERVENTIONS: (state, entityInterventions) => {
    state.entityInterventions = entityInterventions;
  },
  SET_ENTITY_INTERVENTIONS_STOCK_CONTENT: (state, allStocksContent) => {
    state.entityInterventions.forEach(entityIntervention => {
      entityIntervention.stockContent = allStocksContent[entityIntervention.entity?.stockId];
    });
  },
  SET_ZONES: (state, zones) => {
    state.zones = zones;
  },
  SET_FORFAITS: (state, forfaits) => {
    state.forfaits = forfaits;
  },
  SET_QUERY: (state, query) => {
    state.query = query;
  },
  SET_FEEDBACK_MESSAGES: (state, messages) => {
    state.feedbackMessages = messages;
  },
  SET_BASIC_PARAMS: (state, params) => {
    state.basicParams = params;
  },
  SET_COMMUNITY_STOCKS: (state, communityStocks) => {
    state.communityStocks = communityStocks;
  },
  ..._ical.mutations
};

const actions = {
  fetchInterventions(context, { query, ...params }) {
    context.commit("SET_BASIC_PARAMS", params);
    return new Promise((resolve, reject) => {
      /* 1. Fetch interventions */
      fetchInterventions({
        ...params,
        query: { ...query, interventionStatusIds: [interventionStatuses.confirmed, interventionStatuses.planned] }
      })
        .then(res => {
          const { results, count } = res.data.body;
          if (!results || !results.length) {
            context.commit("SET_INTERVENTIONS", []);
            resolve();
            return;
          }
          context.commit("SET_INTERVENTIONS_COUNT", count);
          /* 2. Join their files */
          context
            .dispatch("getSignatureFiles", results)
            .then(intersWithFiles => {
              context.commit("SET_INTERVENTIONS", intersWithFiles);
              /* 3. Fetch zones concerned by these interventions */
              context
                .dispatch("fetchZonesAndDivisions", intersWithFiles)
                .then(({ zipCodes, divisions }) => {
                  fetchZones({ query: { zipCodes, divisions } })
                    .then(res => {
                      context.commit("SET_ZONES", res.data.body.results || []);

                      /* 4. Join deliveries to interventions */
                      context
                        .dispatch("fetchDeliveries")
                        .then(() => {
                          resolve();
                        })
                        .catch(errFetchDeliveries => reject(errFetchDeliveries));
                    })
                    .catch(errFetchZones => reject(errFetchZones));
                })
                .catch(errGetZonesAndDivisions => {
                  reject(errGetZonesAndDivisions);
                });
            })
            .catch(errGetIntersWithFiles => {
              reject(errGetIntersWithFiles);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUserEvents(context, params) {
    return new Promise((resolve, reject) => {
      fetchUserEvents(params)
        .then(res => {
          context.commit("SET_USER_EVENTS", res.data?.body || []);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchEntityInterventions(context, { query, ...params }) {
    return new Promise((resolve, reject) => {
      fetchEntityInterventions({ ...params, query: { ...query, entityInterventionStatusIds: [entityInterventionStatuses.confirmed] } })
        .then(res => {
          context.commit("SET_ENTITY_INTERVENTIONS", res.data?.body?.results || []);
          context
            .dispatch("fetchAllStocksContent")
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchZonesAndDivisions(context, interventions) {
    return new Promise(resolve => {
      const zipCodes = [];
      const divisions = [];

      interventions.forEach(intervention => {
        if (intervention.location && intervention.location.zipCode && !zipCodes.includes(intervention.location.zipCode)) {
          zipCodes.push(intervention.location.zipCode);
        }
        if (intervention.divisionId && !divisions.includes(intervention.divisionId)) {
          divisions.push(intervention.divisionId);
        }
      });
      resolve({ zipCodes, divisions });
    });
  },
  fetchDeliveries(context) {
    return new Promise((resolve, reject) => {
      const contractIds = context.state.interventions.map(intervention => intervention.contractId);
      const query = {
        query: {
          active: true,
          contractIds,
          statusIds: [deliveryStatuses.waiting, deliveryStatuses.delivered]
        },
        sort: { deliveryDate: -1 }
      };

      fetchDeliveries(query)
        .then(res => {
          const deliveries = res.data.body.results || [];

          const interventions = context.state.interventions.map(intervention => {
            const interventionDeliveries = deliveries.filter(
              delivery => delivery.contractId === intervention.contractId && delivery.product && delivery.product.showOnTour
            );
            /* For each product ID, we only need to display the most recent so we remove duplicate. As the array is sorted by deliveryDate, the most recent will be kept */
            const uniqueInterventionDeliveries = uniqBy(interventionDeliveries, "productId");
            /* We need to remove old delivered consommables */
            const deliveriesWithoutOldConsommables = removeOldDeliveredConsommables(uniqueInterventionDeliveries);
            Object.assign(intervention, {
              deliveries: deliveriesWithoutOldConsommables
            });
            return intervention;
          });
          context.commit("SET_INTERVENTIONS", interventions);
          resolve();
        })
        .catch(err => {
          context.dispatch("updateFeedbackMessages", { error: err.data.errorMessage });
          reject(err);
        });
    });
  },
  fetchAllStocksContent(context) {
    return new Promise((resolve, reject) => {
      const stockIds = context.state.entityInterventions?.flatMap(entityIntervention => entityIntervention.entity?.stockId);

      const query = {
        stockIds: JSON.stringify(stockIds),
        query: {},
        sort: { label: 1, _id: -1 }
      };

      if (stockIds?.length) {
        fetchAllStocksContent(query)
          .then(res => {
            const allStocksContent = res.data.body || [];
            context.commit("SET_ENTITY_INTERVENTIONS_STOCK_CONTENT", allStocksContent);
            resolve();
          })
          .catch(err => {
            context.dispatch("updateFeedbackMessages", { error: err?.data?.errorMessage });
            reject(err);
          });
      } else {
        resolve();
      }
    });
  },
  searchPatients(context, input) {
    return new Promise((resolve, reject) => {
      searchPatients({ query: { input }, skip: 0, limit: 40, source: "local" })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetData(context) {
    context.commit("SET_QUERY", {});
    context.commit("SET_INTERVENTIONS", []);
    context.commit("SET_ZONES", []);
  },
  updateFeedbackMessages(context, message) {
    const feedbackMessages = getInitialFeedbackMessages();
    context.commit("SET_FEEDBACK_MESSAGES", { ...feedbackMessages, ...message });
  },
  resetFeedbackMessages(context) {
    context.commit("SET_FEEDBACK_MESSAGES", getInitialFeedbackMessages());
  },
  updateQueryFilter(context, query) {
    context.commit("SET_QUERY", query);
    return context.dispatch("fetchByQuery", query);
  },
  fetchByQuery(context) {
    return new Promise(resolve => {
      context.dispatch("resetFeedbackMessages");
      const { technician, start, end, limit, sort } = context.state.query;

      /* Prepare query */
      return Promise.all([
        context.dispatch("fetchInterventions", {
          query: {
            intervenantIds: technician && technician._id ? [technician._id] : undefined,
            dateStart: start,
            dateEnd: end
          },
          limit,
          sort
        }),
        context.dispatch("fetchUserEvents", {
          query: {
            userIds: technician ? [technician._id] : undefined,
            startDateTime: start,
            endDateTime: end
          }
        }),
        context.dispatch("fetchEntityInterventions", {
          query: {
            intervenantIds: technician && technician._id ? [technician._id] : undefined,
            dateStart: start,
            dateEnd: end
          }
        })
      ])
        .then(() => {
          resolve();
        })
        .catch(error => {
          context.dispatch("updateFeedbackMessages", { error: error && error.data ? error.data.errorMessage : defaultErrorMessages.fetch });
          resolve();
        });
    });
  },
  generateInterventionOfflinePdf(context, interventionId) {
    return new Promise((resolve, reject) => {
      const userId = context.rootState.login.user._id;
      generateOfflinePdf(interventionId, { userId })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchCommunityStockContents(context, { stockId, params }) {
    return new Promise((resolve, reject) => {
      if (!stockId) {
        return resolve(null);
      }

      // If already fetched -> resolve
      const stocks = context.state.communityStocks;
      const stock = get(stocks, stockId);
      if (stock) {
        return resolve();
      }

      fetchCommunityStockContents(stockId, params)
        .then(res => {
          context.commit("SET_COMMUNITY_STOCKS", { ...stocks, [stockId]: res.data.body });
          return resolve();
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  ..._ical.actions,
  ..._signature.actions
};

export default { state, getters, mutations, actions, namespaced: true };
