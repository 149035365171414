const documents = [
  {
    slug: "cptcd.pdf",
    folder: "documentsBase",
    filename: "2021-06-15-CPTCD.pdf",
    originalname: "PPC - Certificat de décharge pour désappareillage",
    size: 125012,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["recovery", "partialRecovery"]
  },
  {
    slug: "cptppc.pdf",
    folder: "documentsBase",
    filename: "2021-06-15-CPTPPC.pdf",
    originalname: "PPC - Conditions de remboursement",
    size: 1106741,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"]
  },
  {
    slug: "cptrcb2r.pdf",
    folder: "documentsBase",
    filename: "2021-06-15-CPTRCB2R.pdf",
    originalname: "PPC - Contrat de consentement au télésuivi (pôle Réunion)",
    size: 736000,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["ppc"],
    authorizedChannelIds: ["RN"]
  },
  {
    slug: "ENR_consent_respi_BR.pdf",
    folder: "documentsBase",
    filename: "ENR_consent_respi_BR_16-07-24.pdf",
    originalname: "ENR - Fiche de consentement patient Assistance Respiratoire (BR)",
    size: 241167,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["ppc"],
    prohibitedChannelIds: ["RN"]
  },
  {
    slug: "ENR_consent_respi_PR.pdf",
    folder: "documentsBase",
    filename: "ENR_consent_respi_PR_16-07-24.pdf",
    originalname: "ENR - Fiche de consentement patient Assistance Respiratoire (PR)",
    size: 251452,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["ppc"],
    prohibitedChannelIds: ["RN"]
  },
  {
    slug: "ENR_consent_oxy_BR.pdf",
    folder: "documentsBase",
    filename: "ENR_consent_oxy_BR_16-07-24.pdf",
    originalname: "ENR - Fiche de consentement patient oxygénothérapie (BR)",
    size: 202702,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["oxy"]
  },
  {
    slug: "ENR_consent_oxy_PR.pdf",
    folder: "documentsBase",
    filename: "ENR_consent_oxy_PR_16-07-24.pdf",
    originalname: "ENR - Fiche de consentement patient oxygénothérapie (PR)",
    size: 218790,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: ["installation"],
    treatmentTypeIds: ["oxy"]
  },
  {
    slug: "decharge-o2.pdf",
    folder: "documentsBase",
    filename: "2021-08-16-decharge_O2.pdf",
    originalname: "Oxygénothérapie - Certificat de décharge pour désappareillage",
    size: 426728,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "information-tabagisme.pdf",
    folder: "documentsBase",
    filename: "2022-12-06-information-tabagisme.pdf",
    originalname: "Informations Tabagisme",
    size: 193580,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "inspection-continue-reservoir-o2.pdf",
    folder: "documentsBase",
    filename: "2023-05-31-inspection-continue-reservoir-o2.pdf",
    originalname: "Fiche Inspection Continue réservoir O2",
    size: 151435,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "inspection-continue-reservoir-portable-o2.pdf",
    folder: "documentsBase",
    filename: "2023-05-31-inspection-continue-reservoir-portable-o2.pdf",
    originalname: "Fiche Inspection Continue réservoir portable O2",
    size: 152054,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "extranet-mode-opératoire-collaborateur.pdf",
    folder: "documentsBase",
    filename: "2023-06-21-extranet-mode-operatoire-collaborateur.pdf",
    originalname: "EXTRANET Mode opératoire Collaborateurs",
    size: 511731,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "extranet-mode-opératoire-patient.pdf",
    folder: "documentsBase",
    filename: "2023-06-21-extranet-mode-operatoire-patient.pdf",
    originalname: "EXTRANET Mode opératoire Patient",
    size: 3441864,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "enr-fiche-maintenance-continue-concentrateur-portable.pdf",
    folder: "documentsBase",
    filename: "enr-concentrateur-portable.pdf",
    originalname: "ENR - Fiche de maintenance continue d'un concentrateur portable",
    size: 1470182,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  },
  {
    slug: "enr-fiche-maintenance-continue-concentrateur-fixe.pdf",
    folder: "documentsBase",
    filename: "enr-concentrateur-fixe.pdf",
    originalname: "ENR - Fiche de maintenance continue d'un concentrateur fixe",
    size: 1398715,
    mimetype: "application/pdf",
    isPrivate: true,
    interventionTypeIds: []
  }
];

module.exports = documents;
